<template>
  <template v-if="packages.length > 0">
    <div class="paymentDetail-info mb-3" v-if="selectedPackageObject.bought_sender === false">
      <label for="bulk_agreement" class="cr" v-html="$t('forms.buyPackages.noSenderInfoDescription')"></label>
      <div class="pb-1 d-flex justify-content-between">
        <alert type="primary" text="forms.buyPackages.noSenderWarningAlert" class="mb-0 font-weight-bold" />
      </div>
      <div class="pb-1 d-flex justify-content-between">
        <alert type="info" text="forms.buyPackages.noConversationKitAlert" class="mb-0 font-weight-normal" />
      </div>
    </div>
    <h4 class="text-center mb-4">{{ $t('purchasedTabs.conversation_kits_title') }}</h4>
    <Form
      @submit="submit"
      ref="packages"
    >
      <div class="text-center">
        <span class="paymentDetail-param paymentDetail-param_main">{{ $t('purchasedTabs.conversation_kits_description_1') }}</span>
        <Field
          as="select"
          name="package"
          id="packages"
          class="form-control mb-0 form-group d-inline-flex w-auto mx-2"
          v-model="selectedPackage"
        >
          <option
            :key="item.id"
            :value="item.id"
            v-for="item of packages"
          >{{ item.messages }}
          </option>
        </Field>
        <span class="paymentDetail-param paymentDetail-param_main">
          {{ $t('purchasedTabs.conversation_kits_description_2') }}
        </span>
      </div>
      <div v-if="selectedPackageObject" class="font-weight-bold mt-3 text-center paymentDetail-param_plan paymentDetail-param_plan_main">
        {{ selectedPackageObject.localized_price }}
      </div>
      <div v-if="selectedPackageObject" class="font-weight-bold m-0 text-center paymentDetail-monthly_plan paymentDetail-param_plan_main">
        {{$t('forms.buyPackages.monthly')}}
      </div>
      <div v-if="selectedPackageObject" class="text-center mt-2 mb-3">
        <div v-if="selectedPackageObject.localized_price_per_message">
          {{ $t('forms.buyPackages.price_per_message') }}: {{ selectedPackageObject.localized_price_per_request }}
        </div>
        <div v-if="selectedPackageObject.term">
          <hint title="forms.buyPackages.terms_hint" placement="top">
            {{ $t('forms.buyPackages.messages_terms_label') }}: {{ $t('forms.buyPackages.terms.' + selectedPackageObject.term) }}
          </hint>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button class="btn btn-primary btn-lg shadow-2">
          {{ $t('forms.buyPackages.continue') }}
        </button>
      </div>
    </Form>
  </template>
  <slot></slot>
</template>

<script>
import { Form, Field } from 'vee-validate'
import { computed, ref, defineAsyncComponent } from 'vue'

export default {
  name: 'BuyConversationsKitForm',
  components: {
    Form,
    Field,
    hint: defineAsyncComponent(() => import('@/application/components/elements/Hint.vue')),
    alert: defineAsyncComponent(() => import('@/application/components/elements/Alert.vue')),
  },
  props: {
    packages: {
      type: Array,
    },
  },
  emits: ['select-package'],
  setup(props, { emit }) {
    const agreement = ref(false)
    const selectedPackage = ref(props.packages.length > 0 ? props.packages[0].id : null)
    const selectedPackageObject = computed(() => {
      const selectedPackageObject = props.packages.find(i => i.id === selectedPackage.value)
      return selectedPackageObject ? { ...selectedPackageObject } : null
    })

    return {
      submit: async(value) => {
        const packageId = value.package
        emit('select-package', props.packages.find(i => i.id === packageId))
      },
      selectedPackageObject,
      selectedPackage,
      agreement,
    }
  },
}
</script>
